/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { ButtonAction, Button } from "@components/Button"
import { splitText } from "@utils"

const ESGebookPage = ({ data }) => {
  const { aboutJKImage, coverImage, cardOneImage, cardTwoImage } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "ungated_download", { event_category: "download", event_label: "esg_ebook" })
    window.open("/documents/jk-social-good-storytelling-ebook.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="ESG e-book" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container">
            <div className="flex justify-center">
              <div className="pr-4 ml-auto lg:w-11/12">
                <div className="pt-clamp-14-25 pb-clamp-8-16">
                  <h1
                    className="text-clamp-30-70"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "Free e-book: ",
                        "text-teal-light": "Going beyond ESG reporting to emotional storytelling",
                        "text-white-light": "&mdash; and why you should.",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center lg:pb-18">
              <div className="lg:w-10/12">
                <div className="flex flex-col-reverse grid-cols-10 lg:grid">
                  <div className="col-span-6 mt-14 lg:pr-18 lg:mt-0">
                    <div className="relative flex">
                      <div className="absolute inset-0 flex lg:hidden">
                        <div className="mt-auto aspect-w-667 aspect-h-80 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
                      </div>
                      <div data-aos="fade-right">
                        <GatsbyImage
                          className="relative"
                          objectFit="contain"
                          image={getImage(coverImage)}
                          alt="Now what?"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="lg:pt-18" data-aos="fade-left">
                      <p className="text-white">
                        Learn five ways to tell a more powerful social good story and the rewards it can have for your
                        business.
                      </p>
                      <div className="mt-10">
                        <ButtonAction func={downloadGuide} className="p-5 text-gray-darkest bg-teal-light">
                          Download the free e-book now
                        </ButtonAction>
                      </div>
                    </div>
                    r
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <div className="container">
            <div className="flex justify-center py-clamp-17-28">
              <div className="grid-cols-10 lg:grid lg:w-10/12">
                <div data-aos="fade-up" className="col-span-8">
                  <h3 className="text-clamp-25-35">
                    Go from information to inspiration! Learn how to transform your ESG data into storytelling that
                    boosts loyalty and inspires action.
                  </h3>
                  <p className="mt-12">
                    There’s a good reason so many companies are adding environmental, social, and governance (ESG)
                    reporting to their yearly to-do lists. They’re seeing the value it has for their business.
                  </p>
                  <p className="mt-12">
                    But research shows that people connect more powerfully with stories than with even the most
                    compelling facts and figures.
                  </p>
                  <p className="mt-12">
                    Our e-book highlights <span className="font-bold">five reasons</span> your business can benefit from
                    emotionally driven ESG storytelling and <span className="font-bold">five ways</span> to harness its
                    power—along with research to back them up and examples of companies doing it well.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="container">
              <div className="grid gap-4 grid-col-1 lg:grid-cols-2">
                <div>
                  <GatsbyImage className="relative" objectFit="contain" image={getImage(cardOneImage)} alt="Card 1" />
                </div>
                <div>
                  <GatsbyImage className="relative" objectFit="contain" image={getImage(cardTwoImage)} alt="Card 2" />
                </div>
              </div>
            </div>
            <div className="container mt-20">
              <div className="flex justify-center">
                <div className="grid-cols-10 lg:grid lg:w-10/12">
                  <div className="lg:col-span-7">
                    <h3>Get the free e-book now!</h3>
                    <p className="mt-6 lg:mt-14">
                      Download our FREE e-book now and begin your journey to telling a more powerful social good story.
                    </p>
                    <ButtonAction func={downloadGuide} className="mt-10 lg:mt-12 text-gray-darkest">
                      Download now
                    </ButtonAction>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mt-24 lg:mt-0">
            <div className="lg:container">
              <div className="flex justify-center">
                <div className="flex flex-col-reverse grid-cols-10 gap-x-4 lg:grid lg:w-10/12">
                  <div className="relative col-start-1 col-end-11 row-start-1 row-end-2 lg:mt-28">
                    <div className="w-full h-full bg-teal-light extend before:bg-teal-light"></div>
                  </div>
                  <div className="container relative col-span-6 col-start-2 row-start-1 row-end-2 lg:max-w-full lg:px-0 py-clamp-12-19 lg:mt-28">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-right" className="relative sm:pr-18">
                      <h3 className="text-clamp-35-62">About JK.</h3>
                      <h4 className="mt-6 text-clamp-25-35">
                        A powerful story makes all the difference. We’re expert storytellers.
                      </h4>
                      <p className="mt-10">
                        We are a women-owned, full-service creative agency with almost 40 years of experience in
                        branding, talent engagement, and social good communications.
                      </p>
                      <p className="mt-clamp-9-16">
                        But what makes all of these truly powerful is a compelling story—a reason for people to take
                        notice, to engage, to believe. And that’s our specialty.
                      </p>
                      <Button link="/contact-us" className="text-gray-darkest mt-clamp-11-17">
                        Contact us
                      </Button>
                    </div>
                  </div>
                  <div className="container relative col-start-8 col-end-11 row-start-1 row-end-2 pr-0 lg:max-w-full lg:px-0">
                    <div className="absolute inset-0 grid grid-cols-12 lg:hidden">
                      <div className="col-span-12 mt-auto h-1/2 lg:hidden sm:col-span-11 bg-teal-light extend before:bg-teal-light"></div>
                    </div>
                    <div data-aos="fade-left" className="lg:min-w-204">
                      <GatsbyImage
                        className="relative"
                        objectFit="contain"
                        image={getImage(aboutJKImage)}
                        alt="About JK"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default ESGebookPage

export const ESGebookQuery = graphql`
  query eSGebookQuery {
    aboutJKImage: file(relativePath: { eq: "esg/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    coverImage: file(relativePath: { eq: "esg/cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cardOneImage: file(relativePath: { eq: "esg/card-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cardTwoImage: file(relativePath: { eq: "esg/card-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
